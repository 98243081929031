import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Sobre Nosotros</h2>
              <p>{props.data ? props.data.paragraph1 : "loading..."}</p>
              <p>{props.data ? props.data.paragraph2 : "loading..."}</p>
            </div>
          </div>
        </div>
        <div className="about-text">
          <h3>¿Por qué elegirnos?</h3>
          <div className="list-style">
            <div className="col-lg-6 col-sm-6 col-xs-12">
              <ul>
                {props.data
                  ? props.data.Why.map((d, i) => <li key={`${d}-${i}`}>{d}</li>)
                  : "loading"}
              </ul>
            </div>
            <div className="col-lg-6 col-sm-6 col-xs-12">
              <ul>
                {props.data
                  ? props.data.Why2.map((d, i) => (
                      <li key={`${d}-${i}`}> {d}</li>
                    ))
                  : "loading"}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
